<template>
  <div class="container">
    <div class="row">
      <div class="col">Form view</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>